import { ContentBuilder } from "@components/ContentBuilder";
import { Layout } from "@components/Layout";
import { Stack } from "@mui/material";
import { GetStaticPaths, GetStaticProps } from "next";
import { APITypes } from "utils/api.types";
import { fetchAllPages, fetchPageBySlug } from "utils/fetchingFunctions";

interface PageProps {
    page: APITypes.Page;
    isScriptLoaded: boolean;
}

export default function Page({ page, isScriptLoaded }: PageProps) {
    return (
        <Layout meta={page?.seo}>
            <Stack>
                {/*NavBar*/}
                {page.navigationBar && (
                    <ContentBuilder
                        contentBlocks={page.navigationBar.content}
                        slug={page.slug}
                        localizations={page.localizations}
                    />
                )}
                <ContentBuilder
                    contentBlocks={page.content}
                    slug={page.slug}
                    localizations={page.localizations}
                />

                {/*Contact*/}
                {page.contact && (
                    <ContentBuilder
                        contentBlocks={page.contact.content}
                        slug={page.slug}
                        localizations={page.localizations}
                        isScriptLoaded={isScriptLoaded}
                    />
                )}
            </Stack>
            {/*Footer*/}
            {page.footer && (
                <ContentBuilder
                    contentBlocks={page.footer.content}
                    slug={page.slug}
                    localizations={page.localizations}
                />
            )}
        </Layout>
    );
}

export const getStaticProps: GetStaticProps<
    {
        page?: APITypes.Page;
    },
    { slug: string }
> = async (context: any) => {
    try {
        const { params, locale, defaultLocale } = context;
        if (params) {
            let localeToFetch = (locale ?? defaultLocale) as any;
            if (params.slug) {
                /* if (params.slug[1] === "detail") {
                    return {
                        redirect: {
                            destination: locale === "fr-FR" ? "/offres-demploi" : "/vacatures",
                            statusCode: 308
                        }
                    }
                } */
                const slug = Array.isArray(params.slug)
                    ? params.slug.join("/")
                    : params.slug;

                if (localeToFetch === "fr-FR" && params.slug.includes("it")) {
                    localeToFetch = "en";
                }

                const { data: page } = await fetchPageBySlug(
                    slug,
                    localeToFetch,
                );
                if (page && page.length > 0) {
                    return {
                        props: {
                            page: page[0],
                        },
                    };
                }
            }
        }

        return { notFound: true };
    } catch (error) {
        console.error(error);
        return {
            redirect: {
                destination: `/`,
                statusCode: 307,
            },
        };
    }
};

export const getStaticPaths: GetStaticPaths = async () => {
    const { data: pages } = await fetchAllPages();

    const paths = pages.reduce((allPages: any, currentPage: any) => {
        if (
            currentPage &&
            currentPage.slug &&
            (currentPage.slug !== "index" ||
                currentPage.slug !== "vacatures" ||
                currentPage.slug !== "offres-demploi")
        ) {
            const slugParts = currentPage.slug.split("~");
            allPages.push({
                params: {
                    slug: slugParts,
                },
                locale: currentPage.locale,
            });
            if (slugParts.includes("blogs")) {
                allPages.push({
                    params: {
                        slug: slugParts,
                    },
                    locale: "fr-FR",
                });
            }
        }

        return allPages;
    }, [] as any);
    paths.push({ params: { slug: ["it"] }, locale: "fr-FR" });
    return {
        paths,
        fallback: false,
    };
};
